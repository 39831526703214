
import React from 'react'
import {
    FacebookShareButton, FacebookIcon,
    TwitterIcon, LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton, WhatsappIcon,
    PinterestShareButton, LinkedinIcon,
    PinterestIcon,
    EmailShareButton, EmailIcon
} from 'react-share';

import Grid from "@material-ui/core/Grid"
import { Blog, Wrapper, Title, SocialContainer } from '../components/styles/styles'

const SocialShare = props => (
    <SocialContainer>
        <FacebookShareButton aria-label="facebook" url={props.url}>
            <FacebookIcon logoFillColor="white" iconBgStyle={{ fill: "#323232" }} size={32} round={true} />
        </FacebookShareButton>

        <TwitterShareButton aria-label="twitter" url={props.url}>
            <TwitterIcon logoFillColor="white" iconBgStyle={{ fill: "#323232" }} size={32} round={true} />
        </TwitterShareButton>

        <LinkedinShareButton  aria-label="linkedin" url={props.url}>
            <LinkedinIcon logoFillColor="white" iconBgStyle={{ fill: "#323232" }} size={32} round={true} />
        </LinkedinShareButton>

        <WhatsappShareButton  aria-label="facebook" whatsapp={props.url}>
            <WhatsappIcon logoFillColor="white" iconBgStyle={{ fill: "#323232" }} size={32} round={true} />
        </WhatsappShareButton>

        <PinterestShareButton url={props.url}>
            <PinterestIcon logoFillColor="white" iconBgStyle={{ fill: "#323232" }} size={32} round={true} />
        </PinterestShareButton>

        <EmailShareButton url={props.url}>
            <EmailIcon logoFillColor="white" iconBgStyle={{ fill: "#323232" }} size={32} round={true} />
        </EmailShareButton>
    </SocialContainer>
)

export default SocialShare