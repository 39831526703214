import Grid from "@material-ui/core/Grid"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SocialShare from "../components/socialShare"
import {
  SubSection,
  Title,
  TitleSocialWrapper,
  Wrapper,
} from "../components/styles/styles"
import styled from "styled-components"
import { FontFamilyText } from "../components/styles/constants"
import SuggestionsBox from "../components/suggestion_v2"
import { formatDate } from "../utils/utils"

const BlogTemplate = props => {
  const { data } = props

  console.log("recipe mark", data)
  return (
    <Layout>
      <SEO title={data.markdownRemark.frontmatter.title} />
      <Grid container spacing={1}>
        <Grid item lg={12} xs={12} sm={12}>
          {data.markdownRemark.frontmatter.featuredImage ? (
            <Img
              // imgStyle={{ objectFit: "scale-down" }}
              style={{ width: "100%", height: "400px" }}
              fixed={
                data.markdownRemark.frontmatter.featuredImage.childImageSharp
                  .fixed
              }
            />
          ) : (
            <img
              src="https://miaggrego.vinitalytour.com/wp-content/uploads/2020/02/image-placeholder.png"
              alt=""
            />
          )}
        </Grid>
        <Grid item lg={12} xs={12} sm={12}>
          <TitleSocialWrapper>
            <Title
              size="36px"
              dangerouslySetInnerHTML={{
                __html: data.markdownRemark.frontmatter.title,
              }}
            />
          </TitleSocialWrapper>
        </Grid>
        <Grid item lg={9} xs={12} sm={12}>
          <Wrapper>
            <Title size="24px">
              {data.markdownRemark.frontmatter.subtitle}{" "}
            </Title>
            <Title size="16px">
              {formatDate(data.markdownRemark.frontmatter.date)}{" "}
            </Title>
            <SocialShare url={props.location.href} />
            <hr />
            <SubSection>
              <span>
                <h6>Serves:</h6>{" "}
                <h5>{data.markdownRemark.frontmatter.serving}</h5>
              </span>
              <span>
                <h6>Preparation time: </h6>
                <h5>{data.markdownRemark.frontmatter.preparationTime}</h5>
              </span>
            </SubSection>
            <hr />
            <Title size="24px">INGREDIENTS</Title>

            <ContentSection
              size="16px"
              dangerouslySetInnerHTML={{
                __html: data.markdownRemark.frontmatter.ingredients,
              }}
            />
            <Title size="24px">PREPARATION</Title>
            <ContentSection
              size="18px"
              dangerouslySetInnerHTML={{
                __html: data.markdownRemark.frontmatter.content,
              }}
            />
          </Wrapper>
        </Grid>
        <Grid item lg={3} xs={12} sm={12}>
          <SuggestionsBox data={data.markdownRemark.frontmatter} />
        </Grid>
      </Grid>
    </Layout>
  )
}
export default BlogTemplate

const ContentSection = styled.div`
  margin-top: 1%;
  // letter-spacing: 1px;
  font-size: ${props => props.size};
  line-height: 1.5rem;
  color: black;
  p {
    margin-bottom: 1%;
  }
  font-family: ${FontFamilyText};
  // line-height: 1.7rem;
`
export const BlogTemplateQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        title
        subtitle
        date
        content
        preparationTime
        serving
        category
        region
        ingredients
        suggested_articles
        suggested_recipes
        suggested_wines
        featuredImage {
          childImageSharp {
            fixed(quality: 100, height: 600) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
